import * as React from 'react';
import { Text } from 'wix-ui-tpa/Text';
import s from './OfferingDetails.st.css';
import {
  RunningEnvironmentProps,
  withRunningEnvironmentContext,
} from '../../context/running-environment-context';

interface OfferingDetailsProps extends RunningEnvironmentProps {
  ellipsis?: boolean;
  children?;
  htmlTag?: string;
  ariaHidden?;
}

const OfferingDetailsComponent = ({
  runningEnvironment,
  ellipsis = false,
  children,
  htmlTag,
  ariaHidden = false,
  ...rest
}: OfferingDetailsProps) => {
  const { isMobile } = runningEnvironment;
  return (
    <div
      className={s.detailsWrapper}
      {...{ 'aria-hidden': ariaHidden ? ariaHidden : undefined }}
    >
      <Text
        typography="listText"
        tabIndex={-1}
        {...s('root', { disableFontSize: isMobile, isMobile, ellipsis }, rest)}
        tagName={htmlTag}
        data-hook="offering-details-title"
      >
        {children}
      </Text>
    </div>
  );
};

export const OfferingDetails = withRunningEnvironmentContext(
  OfferingDetailsComponent,
);
