import * as React from 'react';
import { OfferingImage } from '../../OfferingImage/OfferingImage';
import { ImageViewModel } from '../../../domain/offering-view-model-factory';
import s from './OfferingViewImage.st.css';
import {
  ImageShapeOptions,
  OfferingListLayoutOptions,
} from '../../../../Shared/appKeys/SettingsKeys';

interface OfferingViewImageProps {
  imageViewModel: ImageViewModel;
  width: number;
  height: number;
  onClick: any;
  forwardedRef: any;
  layout: OfferingListLayoutOptions;
}

class OfferingViewImage extends React.PureComponent<OfferingViewImageProps> {
  render() {
    const {
      imageViewModel,
      height,
      onClick,
      layout,
      forwardedRef,
    } = this.props;
    const {
      shape,
      imageResize,
      mediaItem,
      focalPoint,
      aspectRatio,
    } = imageViewModel;
    const isRoundGrid =
      shape === ImageShapeOptions.ROUND &&
      layout === OfferingListLayoutOptions.GRID;

    const width = isRoundGrid ? height : this.props.width;
    const innerPadding = isRoundGrid ? 32 : 0;

    return (
      <div className={s.imageWrapper} ref={forwardedRef}>
        <div
          {...s('root', { shape: isRoundGrid ? 'round' : 'square' })}
          style={{
            height: isRoundGrid ? `calc(100% - ${innerPadding}px)` : null,
            width: isRoundGrid
              ? `calc(${100 / aspectRatio}% - ${innerPadding}px)`
              : null,
          }}
        >
          <div
            data-hook="offering-view-image"
            onClick={onClick}
            className={s.clickable}
            role="presentation"
            tabIndex={-1}
          >
            <OfferingImage
              width={width}
              height={height}
              imageResize={imageResize}
              mediaItem={mediaItem}
              focalPoint={focalPoint}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default OfferingViewImage;
